<template>
  <mdb-container fluid>
    <!-- Section: Grid -->
    <section style="height: calc(100vh - 117px);">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :toolbar="toolbar"
        :toolbarClick="clickToolbar"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :selectionSettings="selectionOptions"
        :rowSelected="onRowSelected"
        :rowDeselected="onRowDeselected"
        :recordDoubleClick="recordDoubleClick"
        :editSettings="editSettings"
        :queryCellInfo="queryCellInfo"
        :actionComplete="actionComplete"
      >
        <e-columns>
          <e-column
            type="checkbox"
            width="50"
          ></e-column>
          <e-column
            field="created_formatted"
            headerText="Anmeldedatum"
            textAlign="Left"
          ></e-column>
          <e-column
            field="id"
            headerText="ID"
            textAlign="Left"
            :isPrimaryKey="true"
            :visible="false"
          ></e-column>
          <e-column
            field="reference"
            headerText="Stand"
            textAlign="Left"
          ></e-column>
          <e-column
            field="segment"
            headerText="Gruppe"
            textAlign="Left"
            :disableHtmlEncode="false"
            :visible="false"
          ></e-column>
          <e-column
            field="company"
            headerText="Firma"
            textAlign="Left"
            :customAttributes="labelColumnAttributes"
          ></e-column>
          <e-column
            field="city"
            headerText="Standort"
            textAlign="Left"
          ></e-column>
          <e-column
            field="first_name"
            headerText="Vorname"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="last_name"
            headerText="Nachname"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="full_name"
            headerText="Ansprechpartner"
            textAlign="Left"
          ></e-column>
          <e-column
            field="phone"
            headerText="Telefon"
            textAlign="Left"
          ></e-column>
          <e-column
            field="email"
            headerText="E-Mail"
            textAlign="Left"
          ></e-column>
          <e-column
            field="memo"
            headerText="<i class='fas fa-comment' data-toggle='tooltip' data-placement='top' title='Kommentar'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
          ></e-column>
          <e-column
            field="talk"
            headerText="<i class='fas fa-microphone table-header-icon red-text' data-toggle='tooltip' data-placement='top' title='Vortrag'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="41"
            :customAttributes="statusColumnAttributes"
          ></e-column>
          <e-column
            field="dinner"
            headerText="<i class='fas fa-glass-cheers table-header-icon red-text' data-toggle='tooltip' data-placement='top' title='Abendveranstaltung'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="41"
            :customAttributes="statusColumnAttributes"
          ></e-column>
          <e-column
            field="floor_space_additional"
            headerText="<i class='fas fa-expand-arrows-alt table-header-icon red-text' data-toggle='tooltip' data-placement='top' title='Zusätzliche Standmeter'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="41"
            :customAttributes="statusColumnAttributes"
          ></e-column>
          <e-column
            field="authorized"
            headerText="<i class='e-btn-icon e-icon-chevron-right-double e-icons e-icon-left table-header-icon' data-toggle='tooltip' data-placement='top' title='Account gesendet'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="41"
            :customAttributes="statusColumnAttributes"
          ></e-column>
          <e-column
            field="visited"
            headerText="<i class='e-btn-icon e-icon-check-box e-icons e-icon-left table-header-icon' data-toggle='tooltip' data-placement='top' title='Status'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="41"
            :customAttributes="statusColumnAttributes"
          ></e-column>
          <!--<e-column
            field="confirmed"
            headerText="<i class='e-btn-icon e-icon-check-box-2 e-icons e-icon-left table-header-icon' data-toggle='tooltip' data-placement='top' title='Übermittelter Status'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="41"
            :customAttributes="statusColumnAttributes"
          ></e-column>-->
        </e-columns>
      </ejs-grid>
    </section>
    <!-- /Section: Grid -->
    <!-- Modal: Account -->
    <mdb-modal
      centered
      size="fluid"
      scrollable
      removeBackdrop
      :show="showModal"
      @close="closeModal"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title v-if="modalEdit">Account bearbeiten</mdb-modal-title>
        <mdb-modal-title v-else>Account hinzufügen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="modal-form" novalidate @submit.prevent="saveModal">
          <mdb-row>
            <mdb-col md="4" class="mb-3" style="border-right: 1px solid #aaa;">
              <legend><mdb-icon icon="user-cog" class="red-text" /> Account</legend>
              <input type="hidden" v-model="model.id">
              <!--<mdb-select
                label="Gruppe"
                class="my-0 mt-3 mb-0"
                v-model="model.segment"
                ref="segment"
                validation
                search
              />-->
              <mdb-input
                label="Stand"
                type="text"
                class="mb-3 mt-3"
                v-model="model.reference"
              />
              <mdb-input
                label="Account-ID"
                type="text"
                class="mb-3 mt-3"
                v-model="model.account"
                read-only
                v-show="modalEdit === true"
              />
              <mdb-input
                label="API-Key"
                type="text"
                class="mb-3 mt-3"
                v-model="model.api_key"
                read-only
                v-show="modalEdit === true"
              />
              <mdb-select
                label="Sprache"
                class="my-0 mt-3 mb-0"
                v-model="model.language"
                ref="language"
                validation
              />
              <legend><mdb-icon icon="barcode" fas class="red-text" /> Digitale Leaderfassung</legend>
              <mdb-switch color="red-text" onLabel="Ja" offLabel="Nein" v-model="model.form_app" />
              <legend><mdb-icon icon="user" class="red-text mt-4" /> Ansprechpartner</legend>
              <mdb-input
                label="Firma"
                type="text"
                class="mb-3 mt-3"
                v-model="model.company"
                required
              />
              <mdb-input
                label="Abteilung"
                type="text"
                class="mb-3 mt-3"
                v-model="model.form_department"
              />
              <mdb-select
                label="Anrede"
                class="mt-3 mb-0"
                v-model="model.title"
                ref="title"
                validation
                required
              />
              <mdb-input
                label="Titel"
                type="text"
                class="mt-0 mb-3"
                v-model="model.title_honorific"
              />
              <mdb-input
                label="Vorname"
                type="text"
                class="mb-3 mt-3"
                v-model="model.first_name"
              />
              <mdb-input
                label="Nachname"
                type="text"
                class="mb-3 mt-3"
                v-model="model.last_name"
                required
              />
            </mdb-col>
            <mdb-col md="4" class="mb-3">
              <legend><mdb-icon icon="map-marked-alt" class="red-text" /> Anschrift</legend>
              <mdb-input
                label="Straße"
                type="text"
                class="mb-3 mt-3"
                v-model="model.street"
              />
              <mdb-input
                label="PLZ"
                type="text"
                class="mb-3 mt-3"
                v-model="model.zip"
              />
              <mdb-input
                label="Stadt"
                type="text"
                class="mb-3 mt-3"
                v-model="model.city"
              />
              <mdb-input
                label="Region"
                type="text"
                class="mb-3 mt-3"
                v-model="model.region"
              />
              <mdb-input
                label="Land"
                type="text"
                class="mb-3 mt-3"
                v-model="model.country"
              />
              <legend><mdb-icon icon="phone" class="red-text mt-4" /> Kontakt</legend>
              <mdb-input
                label="Telefon"
                type="text"
                class="mb-3 mt-3"
                v-model="model.phone"
              />
              <mdb-input
                label="Mobil"
                type="text"
                class="mb-3 mt-3"
                v-model="model.mobile"
              />
              <mdb-input
                label="Fax"
                type="text"
                class="mb-3 mt-3"
                v-model="model.fax"
              />
              <mdb-input
                label="E-Mail"
                type="text"
                class="mb-3 mt-3"
                v-model="model.email"
                required
              />
            </mdb-col>
            <mdb-col md="4" class="mb-3">
              <legend><mdb-icon icon="comment" class="red-text" /> Anmerkung</legend>
              <vue-editor
                id="memoEditor"
                v-model="model.memo"
                :rows="4"
                :editor-toolbar="editorToolbar"
              />
              <legend><mdb-icon icon="file-alt" class="red-text mt-4" /> Formulardaten</legend>
              <mdb-input
                label="Teilnehmerzahl"
                type="text"
                class="mb-3 mt-3"
                v-model="model.form_participant_count"
              />
              <mdb-input
                type="textarea"
                label="Teilnehmerdaten"
                :rows="5"
                v-model="model.form_participant_data"
              />
              <mdb-input
                label="Standfläche"
                type="text"
                class="mb-3 mt-3"
                v-model="model.form_floor_space"
              />
              <mdb-input
                label="Zusätzliche Standfläche"
                type="text"
                class="mb-3 mt-3"
                v-model="model.form_floor_space_additional"
              />
              <mdb-input
                label="Keine Platzierung neben"
                type="text"
                class="mb-3 mt-3"
                v-model="model.form_placement"
              />
              <mdb-input
                label="Teilnehmerzahl Abendessen"
                type="text"
                class="mb-3 mt-3"
                v-model="model.form_dinner"
              />
              <mdb-input
                label="Fachvortrag Thema"
                type="text"
                class="mb-3 mt-3"
                v-model="model.form_talk_subject"
              />
              <mdb-input
                label="Fachvortrag Referent/in"
                type="text"
                class="mb-3 mt-3"
                v-model="model.form_talk_speaker"
              />
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="red" @click.native="closeModal">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" v-if="modalEdit">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Speichern
        </mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" v-else>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Hinzufügen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Account -->
    <!-- Modal: Delete -->
    <mdb-modal :show="showDelete" @close="showDelete = false">
      <mdb-modal-header color="red">
        <mdb-modal-title>Accounts löschen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="ban" size="4x" class="red-text ml-3"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die folgenden Accounts wirklich löschen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.full_name">
                      {{ record.full_name }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showDelete = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" @click.native="deleteRecord">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Löschen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Delete -->
    <!-- Modal: Segment -->
    <mdb-modal :show="showSegment" @close="showSegment = false">
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Gruppe zuweisen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="tag" size="4x" class="red-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die gewählten Accounts mit folgender Gruppe verknüpfen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body class="pb-0">
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <mdb-select
                    label="Gruppe"
                    class="mt-3"
                    v-model="model.segment"
                    ref="bulk_segment"
                    search
                  />
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.full_name">
                      {{ record.full_name }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showSegment = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" @click.native="segmentEntry">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Zuweisen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Segment -->
    <!-- Modal: authorizeRecords -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showAuthorizeRecords"
      @shown="authorizeRecordsShown"
      @close="showAuthorizeRecords = false"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Accountdaten senden</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="file-alt" far size="4x" class="red-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Wählen sie eine Gruppe an Ausstellern aus, die Accountdaten per E-Mail erhalten soll:
            </p>
            <mdb-input type="radio" id="authorize-option-1" name="authorizeOptions" radioValue="selection" v-model="action.authorize" :disabled="this.selectedRecords.length === 0" class="mb-2" label="Ihre Auswahl" />
            <mdb-input type="radio" id="authorize-option-2" name="authorizeOptions" radioValue="pending" v-model="action.authorize" class="mb-2" label="Alle Aussteller, die zugestimmt haben und noch keine Zugangsdaten erhalten haben" />
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <div class="mb-2 pl-4">Ausgewählte Accounts:</div>
                  <ul class="mb-0" v-if="this.action.authorize === 'selection'">
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.full_name">
                      {{ record.full_name }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                  <p v-if="this.action.authorize === 'pending'" class="pl-4">{{ authorizeRecordCount }} Accounts ohne Zugangsdaten</p>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showAuthorizeRecords = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" @click.native="authorizeRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Senden
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: authorizeRecords -->
    <!-- Modal: registerRecords -->
    <mdb-modal :show="showRegisterRecords" @close="showRegisterRecords = false">
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Internen Status ändern</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="check-square" size="4x" class="red-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie den internen Status der gewählten Accounts mit folgender Wahl überschreiben?</strong>
            </p>
            <mdb-card>
              <mdb-card-body class="pb-0">
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <mdb-select
                    label="Status"
                    class="mt-3"
                    v-model="action.register"
                    ref="bulk_register"
                    search
                  />
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.full_name">
                      {{ record.full_name }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showRegisterRecords = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" @click.native="registerRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Ändern
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: registerRecords -->
    <!-- Modal: loginRecord -->
    <mdb-modal size="lg" :show="showLoginRecord" @close="showLoginRecord = false">
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Support Login</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="sign-in-alt" size="4x" class="red-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie sich in den gewählten Account einloggen?</strong>
            </p>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <p class="font-weight-bold" v-for="(record) in selectedRecords.slice(0, 5)" :key="record.company">
                    {{ record.company }}
                  </p>
                  <b class="red-text" v-show="consent === false">Der gewählte Account hat keine Zustimmung erteilt!</b>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showLoginRecord = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" @click.native="loginRecord">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Login
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: loginRecord -->
    <!-- Modal: transferRecords -->
    <mdb-modal :show="showTransferRecords" @close="showTransferRecords = false">
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Accounts übertragen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="exchange-alt" size="4x" class="red-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die ausgewählten Accounts auf folgende Veranstaltung übertragen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body class="pb-0">
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <mdb-container>
                    <mdb-select v-model="this.action.eventCategoryOptions" @getValue="getEventCategoryValue" label="Veranstaltungsreihe" />
                  </mdb-container>
                  <mdb-container>
                    <mdb-select v-model="this.action.eventYearOptions" @getValue="getEventYearValue" label="Jahr" />
                  </mdb-container>
                  <mdb-container>
                    <mdb-select v-model="this.action.eventLocationOptions" label="Termin" />
                  </mdb-container>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.full_name">
                      {{ record.full_name }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showTransferRecords = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" @click.native="transferRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Übertragen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: transferRecords -->
    <!-- Modal: exportRecords -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showExportRecords"
      @shown="exportRecordsShown"
      @close="showExportRecords"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Ausstellerdaten exportieren</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="file-export" fas size="4x" class="red-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Wählen sie eine Gruppe an Ausstellern aus, deren Daten in eine Excel-Datei exportiert werden soll:
            </p>
            <mdb-input type="radio" id="export-option-1" name="exportOptions" radioValue="all" v-model="action.export" class="mb-2" label="Alle Aussteller" checked />
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <div class="mb-2 pl-4">Ausgewählte Aussteller:</div>
                  <p v-if="this.action.export === 'all'" class="pl-4">{{ exportRecordCount }} Aussteller</p>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showExportRecords = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" @click.native="exportRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Exportieren
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: exportRecords -->
  </mdb-container>
</template>

<script>
function clearSelection() {
  if (window.getSelection) {
    window.getSelection().removeAllRanges()
  } else if (document.selection) {
    document.selection.empty()
  }
}

import Vue from 'vue'
import { bus } from '../../main'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as cagregorian from 'cldr-data/main/de/ca-gregorian.json'
import * as numbers from 'cldr-data/main/de/numbers.json'
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencies from 'cldr-data/main/de/currencies.json'
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base'
import { GridPlugin, Edit, Toolbar, Resize, Search, Sort } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText,
  mdbSelect,
  mdbSwitch
} from 'mdbvue'
import { VueEditor } from "vue2-editor"

L10n.load({
  'de': {
    'grid': {
      'EmptyRecord': 'Keine Einträge gefunden',
      'GroupDropArea': 'Spalte hier ablegen um zu gruppieren',
      'UnGroup': 'Klicken Sie hier, um die Gruppierung aufheben',
      'Item': 'Eintrag',
      'Items': 'Einträge',
      'Search': 'Suche',
      'FilterButton': 'Filtern',
      'ClearButton': 'Löschen',
      'SelectAll': 'Alle',
      'Blanks': 'Leer',
      'Add': 'Hinzufügen',
      'Edit': 'Bearbeiten',
      'Delete': 'Löschen',
      'Enter the value': 'Suchbegriff eingeben'
    },
    'pager': {
      'currentPageInfo': '{0} von {1} Seiten',
      'totalItemsInfo': '({0} Einträge)',
      'firstPageTooltip': 'Zur ersten Seite',
      'lastPageTooltip': 'Zur letzten Seite',
      'nextPageTooltip': 'Zur nächsten Seite',
      'previousPageTooltip': 'Zurück zur letzten Seit',
      'nextPagerTooltip': 'Zum nächsten Pager',
      'previousPagerTooltip': 'Zum vorherigen Pager'
    }
  }
})
loadCldr(numberingSystems, cagregorian, numbers, timeZoneNames, weekData, currencies)
setCulture('de')
setCurrencyCode('EUR')

Vue.use(GridPlugin)

export default {
  name: 'Accounts',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbSelect,
    mdbSwitch,
    VueEditor
  },
  data () {
    return {
      loading: false,
      consent: true,
      model: {
        id: 'new',
        segment: [],
        reference: '',
        company: '',
        title: [
          { text: 'Herr', value: 'Herr', selected: true },
          { text: 'Frau', value: 'Frau', selected: false }
        ],
        title_honorific: '',
        first_name: '',
        last_name: '',
        street: '',
        zip: '',
        city: '',
        region: '',
        country: '',
        phone: '',
        mobile: '',
        fax: '',
        email: '',
        language: [
          { text: 'Deutsch', value: 'Deutsch', selected: true },
          { text: 'Englisch', value: 'Englisch', selected: false }
        ],
        memo: '',
        form_department: '',
        form_participant_count: 1,
        form_participant_data: '',
        form_floor_space: 'Standard: 3m',
        form_floor_space_additional: '',
        form_placement: '',
        form_dinner: 0,
        form_talk_subject: '',
        form_talk_speaker: '',
        form_app: 0
      },
      action: {
        selection: [],
        register: [
          { text: 'Bearbeitung abgeschlossen', value: 'complete', selected: true },
          { text: 'Kontakt aufgenommen', value: 'inquire', selected: false },
          { text: 'Zurücksetzen', value: 'reset', selected: false }
        ],
        authorize: '',
        eventCategoryOptions: [],
        eventYearOptions: [],
        eventLocationOptions: [],
        export: 'all'
      },
      statusWaiting: false,
      eventCategory: localStorage.getItem('eventCategory'),
      eventYear: localStorage.getItem('eventYear'),
      event: localStorage.getItem('event'),
      selectedRecords: [],
      inviteRecordCount: 0,
      authorizeRecordCount: 0,
      exportRecordCount: 0,
      showModal: false,
      modalEdit: false,
      showDelete: false,
      showSegment: false,
      showConsultant: false,
      showNotifyRecords: false,
      showInviteRecords: false,
      showRegisterRecords: false,
      showAuthorizeRecords: false,
      showLoginRecord: false,
      showTransferRecords: false,
      showImportRecords: false,
      showExportRecords: false,
      wasValidated: false,
      dataSource: new DataManager({
        url: Vue.prototype.$serverUrl + 'api/list_accounts.php',
        adaptor: new UrlAdaptor,
        crossDomain: true
      }),
      toolbar: [
        { text: 'Hinzufügen', tooltipText: 'Einen Account hinzufügen', prefixIcon: 'e-add', id: 'addRecord' },
        { text: 'Bearbeiten', tooltipText: 'Einen Account bearbeiten', prefixIcon: 'e-edit', id: 'editRecord' },
        { text: 'Löschen', tooltipText: 'Einen oder mehrere Accounts löschen', prefixIcon: 'e-delete', id: 'deleteRecord' },
        { type: 'Separator' },
        // { text: 'Gruppe', tooltipText: 'Accounts eine Gruppe zuweisen', prefixIcon: 'e-icon-bookmark', id: 'segmentRecord' },
        { text: 'Status', tooltipText: 'Status ändern', prefixIcon: 'e-icon-check-box', id: 'registerRecords' },
        { type: 'Separator' },
        { text: 'Accountdaten', tooltipText: 'Accountdaten versenden', prefixIcon: 'e-icon-chevron-right-double', id: 'authorizeRecords' },
        { type: 'Separator' },
        { text: 'Export', tooltipText: 'Ausstellerdaten exportieren', prefixIcon: 'e-icon-export', id: 'exportRecords' },
        // { type: 'Separator' },
        // { text: 'Übertragen', tooltipText: 'Accounts auf andere Veranstaltungen übertragen', prefixIcon: 'e-icon-redo-2', id: 'transferRecords' },
        // { type: 'Separator' },
        // { text: 'Support Login', tooltipText: 'Account-Support Login', prefixIcon: 'e-group-icon', id: 'loginRecord' },
        'Search'
      ],
      filterOptions: {
        type: 'Menu'
      },
      filter: {
        type: 'CheckBox'
      },
      editorToolbar: [
        ["bold", "italic", "underline"]
      ],
      editorToolbarMin: [
        []
      ],
      sortSettings: {
        columns: [
          {
            field: 'created_formatted',
            direction: 'Descending'
          }
        ]
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        allowEditOnDblClick: false
      },
      selectionOptions: {
        checkboxMode: 'Default'
      },
      labelColumnAttributes : {
        class: 'label-column'
      },
      statusColumnAttributes : {
        class: 'status-column'
      }
    }
  },
  created() {
    bus.$on('eventChange', this.eventChange)
  },
  beforeDestroy() {
    bus.$off('eventChange', this.eventChange)
  },
  mounted: function() {
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('keyup', () => {
      this.$refs.grid.search((event.target).value)
    })
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('click', () => {
      this.$refs.grid.ej2Instances.searchSettings.key = '';
    })
    if (localStorage.eventCategory) {
      this.eventCategory = localStorage.eventCategory
    }
    if (localStorage.eventYear) {
      this.eventYear = localStorage.eventYear
    }
    if (localStorage.event) {
      this.event = localStorage.event
    }
    this.resetModel()
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['segmentRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['registerRecords'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['loginRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['transferRecords'], false)
  },
  computed: {
    authorizeAction() {
      return this.action.authorize;
    },
    exportAction() {
      return this.action.export;
    }
  },
  watch: {
    selectedRecords: {
      handler: function () {
        if (this.selectedRecords.length === 0) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['segmentRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['registerRecords'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['transferRecords'], false)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['segmentRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['registerRecords'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['transferRecords'], true)
        }
        if (this.selectedRecords.length === 1) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['loginRecord'], true)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['loginRecord'], false)
        }
      },
      deep: true
    },
    authorizeAction: {
      handler: function () {
        axios.get('get_account_count.php?action=' + this.action.authorize).then(response => {
          this.authorizeRecordCount = response.data
        })
      }
    },
    exportAction: {
      handler: function () {
        axios.get('get_account_count.php?action=' + this.action.export).then(response => {
          this.exportRecordCount = response.data
        })
      }
    }
  },
  methods: {
    eventChange: function () {
      this.$refs.grid.refresh()
    },
    dataBound: function () {
      this.$refs.grid.autoFitColumns(['created_formatted', 'reference', 'segment'])
      //this.$refs.grid.ej2Instances.groupModule.collapseAll()
    },
    actionComplete: function (e) {
      if (e.requestType === 'grouping' || e.requestType === 'searchingss') {
        this.$refs.grid.ej2Instances.groupModule.collapseAll()
        //console.log(this.groupSettings.columns)
      }
    },
    queryCellInfo: function (args) {
      if (args.column.field === 'talk') {
        if (args.data['talk'] === 1) {
          args.cell.classList.add('status-circle', 'status-light-grey')
        } else if (args.data['talk'] === 2) {
          args.cell.classList.add('status-circle', 'status-green')
        }
        args.cell.textContent = ''
      }

      if (args.column.field === 'dinner') {
        if (args.data['dinner'] === 1) {
          args.cell.classList.add('status-circle', 'status-light-grey')
        } else if (args.data['dinner'] === 2) {
          args.cell.classList.add('status-circle', 'status-green')
        }
        args.cell.textContent = ''
      }

      if (args.column.field === 'floor_space_additional') {
        if (args.data['floor_space_additional'] === 1) {
          args.cell.classList.add('status-circle', 'status-light-grey')
        } else if (args.data['floor_space_additional'] === 2) {
          args.cell.classList.add('status-circle', 'status-green')
        }
        args.cell.textContent = ''
      }

      if (args.column.field === 'authorized') {
        if (args.data['authorized'] === 1) {
          args.cell.classList.add('status-circle', 'status-light-grey')
        } else if (args.data['authorized'] === 2) {
          args.cell.classList.add('status-circle', 'status-red')
        } else if (args.data['authorized'] === 3) {
          args.cell.classList.add('status-circle', 'status-green')
        }
        args.cell.textContent = ''
      }

      if (args.column.field === 'confirmed') {
        if (args.data['confirmed'] === 1) {
          args.cell.classList.add('status-circle', 'status-light-grey')
        } else if (args.data['confirmed'] === 2) {
          args.cell.classList.add('status-circle', 'status-green')
        }
        args.cell.textContent = ''
      }

      if (args.column.field === 'visited') {
        if (args.data['visited'] === 1) {
          args.cell.classList.add('status-circle', 'status-red')
        } else if (args.data['visited'] === 2) {
          args.cell.classList.add('status-circle', 'status-amber')
        } else if (args.data['visited'] === 3) {
          args.cell.classList.add('status-circle', 'status-green')
        }
        args.cell.textContent = ''
      }
    },
    clickToolbar: function (args) {
      if (args.item.id === 'addRecord') {
        this.showModal = true
        this.modalEdit = false
      }
      if (args.item.id === 'editRecord') {
        axios.get('get_account.php?id=' + this.selectedRecords[0].id).then(response => {
          this.model = response.data
          if (this.model.form_app === 1) {
            this.model.form_app = '1'
          } else {
            this.model.form_app = '0'
          }
          this.showModal = true
          this.modalEdit = true
        })
      }
      if (args.item.id === 'deleteRecord') {
        this.action.selection = []
        for (var i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showDelete = true
      }
      if (args.item.id === 'segmentRecord') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showSegment = true
      }
      if (args.item.id === 'registerRecords') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showRegisterRecords = true
      }
      if (args.item.id === 'authorizeRecords') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showAuthorizeRecords = true
        //alert('Diese Aktion ist noch nicht freigeschaltet.')
      }
      if (args.item.id === 'loginRecord') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.consent = true
        this.showLoginRecord = true
      }
      if (args.item.id === 'transferRecords') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showTransferRecords = true
      }
      if (args.item.id === 'importRecords') {
        alert('Diese Aktion ist noch nicht freigeschaltet.')
      }
      if (args.item.id === 'exportRecords') {
        this.showExportRecords = true
      }
    },
    onRowSelected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    onRowDeselected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    recordDoubleClick: function(row) {
      clearSelection()
      this.$refs.grid.clearRowSelection()
      this.$refs.grid.selectRow(row.rowIndex)
      axios.get('get_account.php?id=' + row.rowData.id).then(response => {
        this.model = response.data
        this.showModal = true
        this.modalEdit = true
      })
    },
    closeModal () {
      this.showModal = false
      this.resetModel()
      //this.$refs.grid.clearRowSelection()
    },
    saveModal(event) {
      this.wasValidated = true
      event.target.classList.add('was-validated')
      if (this.model.company !== '' && this.model.title !== '' && this.model.first_name !== '' && this.model.last_name !== '' && this.model.email !== '') {
        this.loading = true
        var url = ''
        if (this.modalEdit === true) {
          url = 'edit_account.php'
        } else {
          url = 'add_account.php'
        }
        axios({
          method: 'post',
          url: url,
          data: this.model
        }).then(response => {
          this.loading = false
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            if (this.modalEdit === true) {
              var rowIndex = this.$refs.grid.getRowIndexByPrimaryKey(this.model.id)
              this.$refs.grid.updateRow(rowIndex, this.model)
            } else {
              this.$refs.grid.refresh()
            }
            this.showModal = false
            this.resetModel()
            //this.$refs.grid.clearRowSelection()
          }
        }).catch(error => {
          this.loading = false
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    deleteRecord () {
      this.loading = true
      var url = 'delete_account.php'
      axios({
        method: 'post',
        url: url,
        data: this.action.selection
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showDelete = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    segmentEntry () {
      this.loading = true
      var url = 'segment_account.php'
      const payload = {
        ids: this.action.selection,
        segment: this.model.segment
      }
      axios({
        method: 'post',
        url: url,
        data: payload
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showSegment = false
          this.resetModel()
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    authorizeRecordsShown () {
      if (this.selectedRecords.length === 0) {
        this.action.authorize = 'pending'
      } else {
        this.action.authorize = 'selection'
      }
      axios.get('get_account_count.php?action=' + this.action.authorize).then(response => {
        this.authorizeRecordCount = response.data
      })
    },
    exportRecordsShown () {
      axios.get('get_account_count.php?action=' + this.action.export).then(response => {
        this.exportRecordCount = response.data
      })
    },
    registerRecords () {
      this.loading = true
      var url = 'register_account.php'
      const payload = {
        ids: this.action.selection,
        action: this.action.register
      }
      axios({
        method: 'post',
        url: url,
        data: payload
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showRegisterRecords = false
          this.resetModel()
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    authorizeRecords () {
      this.loading = true
      var url = 'send_accounts.php'
      axios({
        method: 'post',
        url: url,
        data: this.action
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showAuthorizeRecords = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    loginRecord () {
      this.loading = true
      var url = 'login_account.php'
      const payload = {
       ids: this.action.selection,
       action: this.action.register
      }
      axios({
       method: 'post',
       url: url,
       data: payload
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          this.consent = false 
        } else {
          localStorage.setItem('username', response.data.username)
          window.location.href = Vue.prototype.$serverUrl
        }
      }).catch(error => {
       this.loading = false
       alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    transferRecords () {
      this.loading = true
      var url = 'transfer_account.php'
      const payload = {
        ids: this.action.selection,
        action: this.action.eventLocationOptions
      }
      axios({
        method: 'post',
        url: url,
        data: payload
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showTransferRecords = false
          this.resetModel()
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    getEventCategoryValue(value) {
      if (value !== this.eventCategory) {
        this.eventCategory = value
        axios.post('select_events.php', {
          eventCategory: value,
          eventYear: this.eventYear,
          event: this.event
        }).then(response => {
          this.action.eventCategoryOptions = response.data.eventCategoryOptions
          this.action.eventYearOptions = response.data.eventYearOptions
          this.action.eventLocationOptions = response.data.eventLocationOptions
        })
      }
    },
    getEventYearValue(value) {
      if (value !== this.eventYear) {
        this.eventYear = value
        axios.post('select_events.php', {
          eventCategory: this.eventCategory,
          eventYear: value,
          event: this.event
        }).then(response => {
          this.action.eventCategoryOptions = response.data.eventCategoryOptions
          this.action.eventYearOptions = response.data.eventYearOptions
          this.action.eventLocationOptions = response.data.eventLocationOptions
        })
      }
    },
    importRecords () {
      alert('Diese Aktion ist noch nicht freigeschaltet.')
    },
    exportRecords () {
      var url = Vue.prototype.$serverUrl + 'api/export_accounts.php?action=' + this.action.export
      window.open(url)
    },
    resetModel () {
      this.wasValidated = false
      this.model.id = 'new'
      this.model.segment = []
      axios.get('list_segments.php').then(response => {
        this.model.segment = response.data
      })
      this.model.reference = ''
      this.model.company = ''
      this.model.title = [
        { text: 'Herr', value: 'Herr', selected: true },
        { text: 'Frau', value: 'Frau', selected: false }
      ]
      this.model.title_honorific = ''
      this.model.first_name = ''
      this.model.last_name = ''
      this.model.street = ''
      this.model.zip = ''
      this.model.city = ''
      this.model.region = ''
      this.model.country = ''
      this.model.phone = ''
      this.model.mobile = ''
      this.model.fax = ''
      this.model.email = ''
      this.model.memo = ''
      this.model.form_department = ''
      this.model.form_participant_count = 1
      this.model.form_participant_data = ''
      this.model.form_floor_space = 'Standard = 3m'
      this.model.form_floor_space_additional = ''
      this.model.form_placement = ''
      this.model.form_dinner = 0
      this.model.form_talk_subject = ''
      this.model.form_talk_speaker = ''
      this.model.form_app = 0

      axios.post('select_events.php', {
        eventCategory: this.eventCategory,
        eventYear: this.eventYear,
        event: this.event
      }).then(response => {
        this.action.eventCategoryOptions = response.data.eventCategoryOptions
        this.action.eventYearOptions = response.data.eventYearOptions
        this.action.eventLocationOptions = response.data.eventLocationOptions
      })

      axios.get('get_account_count.php?action=' + this.action.export).then(response => {
        this.exportRecordCount = response.data
      })
    }
  },
  provide: {
    grid: [Edit, Toolbar, Resize, Search, Sort]
  }
}
window.format = function(value) {
  var x = value.count
  if (x > 1) {
    x = value.count + ' Einträge'
  } else {
    x = value.count + ' Eintrag'
  }
  switch (value.field) {
    default:
      // return value.field + ': ' + value.key + ' ' + x
      return value.key + ' <span class="grey-text">(' + x + ')</span>'
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.e-grid .e-rowcell.label-column {
  font-weight: bold;
  font-size: 13px;
}

.e-grid .status-column {
  padding-right: 0px !important;
  padding-left: 10px !important;
}

.padding-label label {
  margin-bottom: 0px;
  margin-top: 14px;
}

.select-wrapper {
  position: relative;
  top: -8px;
}

.custom2 .select-wrapper {
  top: -24px;
}

.custom3 .select-wrapper {
  top: -24px;
}

.row .col-md-3 {
  height: 75px !important;
}

#memoEditor {
  height: 100px;
}

.modal-without-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: initial !important;
}

.e-search-icon {
  font-size: 20px !important;
}

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
  width: 295px !important;
}

.e-search input {
  font-size: 17px !important;
}

.table-header-icon {
  font-size: 14px !important;
}

.status-column .e-headercelldiv {
  padding-top: 3px !important;
  padding-left: 4px !important;
}

.status-column:last-child .e-headercelldiv {
  padding-top: 1px !important;
  padding-left: 7px !important;
  color: #1976d2;
}
</style>
