<template>
  <mdb-container fluid>
    <!-- Section: Grid -->
    <section style="height: calc(100vh - 117px);">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :toolbar="toolbar"
        :toolbarClick="clickToolbar"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :selectionSettings="selectionOptions"
        :rowSelected="onRowSelected"
        :rowDeselected="onRowDeselected"
        :recordDoubleClick="recordDoubleClick"
        :editSettings="editSettings"
      >
        <e-columns>
          <e-column
            type='checkbox'
            width='50'
          ></e-column>
          <e-column
            field="id"
            headerText="ID"
            textAlign="Left"
            :isPrimaryKey="true"
            :visible="false"
          ></e-column>
          <e-column
            field="item_number"
            headerText="Artikelnummer"
            textAlign="Left"
          ></e-column>
          <e-column
            field="shorttag"
            headerText="Variante"
            textAlign="Left"
          ></e-column>
          <e-column
            field="label"
            headerText="Bezeichnung"
            textAlign="Left"
            :customAttributes="labelColumnAttributes"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </section>
    <!-- /Section: Grid -->
    <!-- Modal: catalog -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showModal"
      @close="closeModal"
    >
      <mdb-modal-header>
        <mdb-modal-title v-if="modalEdit">Katalog bearbeiten</mdb-modal-title>
        <mdb-modal-title v-else>Katalog hinzufügen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="modal-form" novalidate @submit.prevent="saveModal" @keydown.enter.prevent>
          <mdb-row>
            <mdb-col>
              <mdb-row>
                <mdb-col md="12">
                  <legend><mdb-icon icon="cube" class="blue-text" /> Allgemein</legend>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col>
                  <input type="hidden" v-model="model.id">
                  <mdb-input
                    label="Artikelnummer"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.item_number"
                  />
                  <mdb-input
                    label="Variante"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.shorttag"
                  />
                  <mdb-input
                    label="Bezeichnung"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.label"
                    required
                  />
                </mdb-col>
              </mdb-row>
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="blue" @click.native="closeModal">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" v-if="modalEdit">Speichern</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" v-else>Hinzufügen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: catalog -->
    <!-- Modal: Delete -->
    <mdb-modal :show="showDelete" @close="showDelete = false" danger>
      <mdb-modal-header>
        <p class="heading"><strong>Katalog löschen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="ban" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die gewählten Kataloge wirklich löschen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.label">
                      {{ record.label }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" @click="showDelete = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="danger" @click.native="deleteEntry">Löschen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Delete -->
  </mdb-container>
</template>

<script>
function clearSelection() {
  if (window.getSelection) {
    window.getSelection().removeAllRanges()
  } else if (document.selection) {
    document.selection.empty()
  }
}

import Vue from 'vue'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as cagregorian from 'cldr-data/main/de/ca-gregorian.json'
import * as numbers from 'cldr-data/main/de/numbers.json'
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencies from 'cldr-data/main/de/currencies.json'
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base'
import { GridPlugin, Resize, Toolbar, Sort, Search, Edit } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText
} from 'mdbvue'

L10n.load({
  'de': {
    'grid': {
      'EmptyRecord': 'Keine Einträge gefunden',
      'GroupDropArea': 'Spalte hier ablegen um zu gruppieren',
      'UnGroup': 'Klicken Sie hier, um die Gruppierung aufheben',
      'Item': 'Eintrag',
      'Items': 'Einträge',
      'Search': 'Suche',
      'FilterButton': 'Filtern',
      'ClearButton': 'Löschen',
      'SelectAll': 'Alle',
      'Blanks': 'Leer',
      'Add': 'Hinzufügen',
      'Edit': 'Bearbeiten',
      'Delete': 'Löschen',
      'Enter the value': 'Suchbegriff eingeben'
    },
    'pager': {
      'currentPageInfo': '{0} von {1} Seiten',
      'totalItemsInfo': '({0} Einträge)',
      'firstPageTooltip': 'Zur ersten Seite',
      'lastPageTooltip': 'Zur letzten Seite',
      'nextPageTooltip': 'Zur nächsten Seite',
      'previousPageTooltip': 'Zurück zur letzten Seit',
      'nextPagerTooltip': 'Zum nächsten Pager',
      'previousPagerTooltip': 'Zum vorherigen Pager'
    }
  }
})
loadCldr(numberingSystems, cagregorian, numbers, timeZoneNames, weekData, currencies)
setCulture('de')
setCurrencyCode('EUR')

Vue.use(GridPlugin)

export default {
  name: 'catalogs',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText
  },
  data () {
    return {
      model: {
        id: 'new',
        item_number: '',
        shorttag: '',
        label: ''
      },
      selectedRecords: [],
      showModal: false,
      modalEdit: false,
      showDelete: false,
      deleteList: [],
      wasValidated: false,
      dataSource: new DataManager({
        url: Vue.prototype.$serverUrl + 'api/list_catalogs.php',
        adaptor: new UrlAdaptor,
        crossDomain: true
      }),
      toolbar: [
        { text: 'Hinzufügen', tooltipText: 'Katalog hinzufügen', prefixIcon: 'e-add', id: 'addRecord' },
        { text: 'Bearbeiten', tooltipText: 'Katalog bearbeiten', prefixIcon: 'e-edit', id: 'editRecord' },
        { text: 'Löschen', tooltipText: 'Kataloge löschen', prefixIcon: 'e-delete-4', id: 'deleteRecord' },
        'Search'
      ],
      sortSettings: {
        columns: [{
          field: 'item_number',
          direction: 'Ascending'
        }]
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        allowEditOnDblClick: false
      },
      selectionOptions: {
        checkboxMode: 'Default'
      },
      labelColumnAttributes : {
        class: 'label-column'
      }
    }
  },
  mounted: function() {
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('keyup', () => {
      this.$refs.grid.search((event.target).value)
    });
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('click', () => {
      this.$refs.grid.ej2Instances.searchSettings.key = '';
    });
    this.resetModel()
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
  },
  watch: {
    selectedRecords: {
      handler: function () {
        if (this.selectedRecords.length === 0) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], true)
        }
        if (this.selectedRecords.length === 1) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], true)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
        }
      },
      deep: true
    }
  },
  methods: {
    dataBound: function () {
      this.$refs.grid.autoFitColumns(['item_number', 'shorttag'])
    },
    clickToolbar: function (args) {
      if (args.item.id === 'addRecord') {
        this.showModal = true
        this.modalEdit = false
      }
      if (args.item.id === 'editRecord') {
        axios.get('get_catalog.php?id=' + this.selectedRecords[0].id).then(response => {
          this.model = response.data
          this.showModal = true
          this.modalEdit = true
        })
      }
      if (args.item.id === 'deleteRecord') {
        this.deleteList = []
        for (var i = 0; i < this.selectedRecords.length; i++) {
          this.deleteList.push(this.selectedRecords[i].id)
        }
        this.showDelete = true
      }
    },
    onRowSelected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    onRowDeselected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    recordDoubleClick: function(row) {
      clearSelection()
      this.$refs.grid.clearRowSelection()
      this.$refs.grid.selectRow(row.rowIndex)
      axios.get('get_catalog.php?id=' + row.rowData.id).then(response => {
        this.model = response.data
        this.showModal = true
        this.modalEdit = true
      })
    },
    closeModal () {
      this.showModal = false
      this.resetModel()
    },
    saveModal(event) {
      this.wasValidated = true
      event.target.classList.add('was-validated')
      if (this.model.label !== '') {
        var url = ''
        if (this.modalEdit === true) {
          url = 'edit_catalog.php'
        } else {
          url = 'add_catalog.php'
        }
        axios({
          method: 'post',
          url: url,
          data: this.model
        }).then(response => {
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            if (this.modalEdit === true) {
              var rowIndex = this.$refs.grid.getRowIndexByPrimaryKey(this.model.id)
              this.$refs.grid.updateRow(rowIndex, this.model)
            } else {
              this.$refs.grid.refresh()
            }
            this.showModal = false
            this.resetModel()
          }
        }).catch(error => {
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    deleteEntry () {
      var url = 'delete_catalog.php'
      axios({
        method: 'post',
        url: url,
        data: this.deleteList
      }).then(response => {
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showDelete = false
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    resetModel () {
      this.wasValidated = false
      this.model.id = 'new'
      this.model.item_number = ''
      this.model.shorttag = ''
      this.model.label = ''
    }
  },
  provide: {
    grid: [Toolbar, Search, Resize, Sort, Edit]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.e-grid .e-rowcell.label-column {
  font-weight: normal;
  font-size: 16px;
}

.padding-label label {
  margin-bottom: 0px;
  margin-top: 14px;
}

.select-wrapper {
  position: relative;
  top: -8px;
}

.custom2 .select-wrapper {
  top: -24px;
}

.custom3 .select-wrapper {
  top: -24px;
}

.row .col-md-3 {
  height: 75px !important;
}

.modal-without-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: initial !important;
}

</style>
