<template>
  <mdb-container class="dashboard" fluid>
    <!--Section: Overview-->
    <section class="p-3 pb-3">
      <mdb-row>
        <!--Card: Event-->
        <mdb-col xl="4" lg="6" md="12" sm="12" class="mb-4 pb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="calendar" class="grey"></mdb-icon>
              <div class="data pt-2">
                <h4 class="text-uppercase grey-text">Veranstaltung</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-tbl borderless class="mt-4 mb-0">
                <mdb-tbl-head>
                  <tr>
                    <th class="align-top">Veranstaltung</th>
                    <th>{{ this.eventData.label }}</th>
                  </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                  <tr>
                    <th class="align-top">Datum</th>
                    <td>{{ this.eventData.date }}</td>
                  </tr>
                  <tr>
                    <th class="align-top">Öffnungszeiten</th>
                    <td>{{ this.eventData.start }} - {{ this.eventData.end }}</td>
                  </tr>
                  <tr>
                    <th class="align-top">Veranstaltungsort</th>
                    <td>{{ this.eventData.street }}<br>{{ this.eventData.zip }} {{ this.eventData.city }}, {{ this.eventData.country }}</td>
                  </tr>
                  <tr v-show="this.usergroup === 'supplier'">
                    <th class="align-top">App-Zugangsdaten</th>
                    <td>
                      <p class="mb-1"><a class="blue-text font-weight-bold" href="https://katalog.gww-leadforge.de" target="_blank">https://katalog.gww-leadforge.de</a></p>
                      <p class="mb-1">Benutzername: <b>{{ this.credentialsData.username }}</b></p>
                      <p class="mb-1">App-Passwort: <b>{{ this.credentialsData.api_key }}</b></p>
                    </td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
            </mdb-card-body>
            <mdb-card-footer class="text-muted mt-2">
              <mdb-switch
                offLabel="mehr anzeigen"
                onLabel=""
                class="float-right invisible"
                v-model="eventDetails"
              />
              <mdb-icon icon="user-tie"></mdb-icon> {{ this.credentialsData.company }}
            </mdb-card-footer>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Event-->
        <!--Card: Checklist-->
        <mdb-col xl="4" lg="6" md="12" sm="12" class="mb-4 pb-4" v-show="this.usergroup === 'trader'">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="clipboard-check" class="grey"></mdb-icon>
              <div class="data pt-2">
                <h4 class="text-uppercase grey-text">Checkliste</h4>
                <h4 class="font-weight-bold dark-grey-text">{{ checklistPercentage }}%</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <h6 class="mb-3"><mdb-icon icon="user-tie" size="lg" color="grey" /> Aussteller</h6>
              <mdb-progress class="mb-2" :height="16" :value=appAuthorizedPercentage color="red" v-if="appAuthorizedPercentage < 50" />
              <mdb-progress class="mb-2" :height="16" :value=appAuthorizedPercentage color="orange" v-if="appAuthorizedPercentage >= 50 && appAuthorizedPercentage < 75" />
              <mdb-progress class="mb-2" :height="16" :value=appAuthorizedPercentage color="yellow" v-if="appAuthorizedPercentage >= 75 && appAuthorizedPercentage < 100" />
              <mdb-progress class="mb-2" :height="16" :value=appAuthorizedPercentage color="green" v-if="appAuthorizedPercentage === 100" />
              <mdb-card-text class="mb-4">{{ supplierData.authorized }} von {{ supplierData.app }} Accounts versendet (<span class="font-weight-bold">{{ appAuthorizedPercentage }}%</span>)</mdb-card-text>
              <section v-show="checklistDetails === true" class="mb-3">
                <mdb-progress class="mb-2" :height="16" :value=statusPercentage color="red" v-if="statusPercentage < 50" />
                <mdb-progress class="mb-2" :height="16" :value=statusPercentage color="orange" v-if="statusPercentage >= 50 && statusPercentage < 75" />
                <mdb-progress class="mb-2" :height="16" :value=statusPercentage color="yellow" v-if="statusPercentage >= 75 && statusPercentage < 100" />
                <mdb-progress class="mb-2" :height="16" :value=statusPercentage color="green" v-if="statusPercentage === 100" />
                <mdb-card-text class="mb-4">{{ supplierData.status }} von {{ supplierData.all }} Aussteller als abgeschlossen markiert (<span class="font-weight-bold">{{ statusPercentage }}%</span>)</mdb-card-text>
              </section>
              <hr class="mt-4 mb-4">
              <h6 class="mb-3"><mdb-icon icon="users" size="lg" color="grey" /> Teilnehmer</h6>
              <mdb-progress class="mb-2" :height="16" :value=invitedPercentage color="red" v-if="invitedPercentage < 50" />
              <mdb-progress class="mb-2" :height="16" :value=invitedPercentage color="orange" v-if="invitedPercentage >= 50 && invitedPercentage < 75" />
              <mdb-progress class="mb-2" :height="16" :value=invitedPercentage color="yellow" v-if="invitedPercentage >= 75 && invitedPercentage < 100" />
              <mdb-progress class="mb-2" :height="16" :value=invitedPercentage color="green" v-if="invitedPercentage === 100" />
              <mdb-card-text class="mb-4">{{ invitationData.invited }} von {{ invitationData.all }} Einladungen versendet (<span class="font-weight-bold">{{ invitedPercentage }}%</span>)</mdb-card-text>
              <mdb-progress class="mb-2" :height="16" :value=authorizedPercentage color="red" v-if="authorizedPercentage < 50" />
              <mdb-progress class="mb-2" :height="16" :value=authorizedPercentage color="orange" v-if="authorizedPercentage >= 50 && authorizedPercentage < 75" />
              <mdb-progress class="mb-2" :height="16" :value=authorizedPercentage color="yellow" v-if="authorizedPercentage >= 75 && authorizedPercentage < 100" />
              <mdb-progress class="mb-2" :height="16" :value=authorizedPercentage color="green" v-if="authorizedPercentage === 100" />
              <mdb-card-text class="mb-4">{{ ticketData.authorized }} von {{ responseData.confirmed }} Tickets versendet (<span class="font-weight-bold">{{ authorizedPercentage }}%</span>)</mdb-card-text>
              <section v-show="checklistDetails === true">
                <hr class="mt-4 mb-4">
                <h6 class="mb-3"><mdb-icon icon="handshake" size="lg" color="grey" /> Anfragen</h6>
                <mdb-progress class="mb-2" :height="16" :value=clearancePercentage color="red" v-if="clearancePercentage < 50" />
                <mdb-progress class="mb-2" :height="16" :value=clearancePercentage color="orange" v-if="clearancePercentage >= 50 && clearancePercentage < 75" />
                <mdb-progress class="mb-2" :height="16" :value=clearancePercentage color="yellow" v-if="clearancePercentage >= 75 && clearancePercentage < 100" />
                <mdb-progress class="mb-2" :height="16" :value=clearancePercentage color="green" v-if="clearancePercentage === 100" />
                <mdb-card-text class="mb-0">{{ requestData.clearance }} von {{ requestData.all }} Anfragen als abgeschlossen markiert (<span class="font-weight-bold">{{ clearancePercentage }}%</span>)</mdb-card-text>
              </section>
            </mdb-card-body>
            <mdb-card-footer class="text-muted mt-2">
              <mdb-switch
                offLabel="mehr anzeigen"
                onLabel=""
                class="float-right"
                v-model="checklistDetails"
              />
            </mdb-card-footer>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Checklist-->
      </mdb-row>
    </section>
    <!--/.Section: Overview-->
    <!--Section: Statistics-->
    <section class="p-3 pb-5" v-show="this.usergroup === 'trader'">
      <mdb-row>
        <!--Card: Suppliers-->
        <mdb-col xl="4" lg="6" md="12" sm="12" class="mb-4 pb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="user-tie" class="grey"></mdb-icon>
              <div class="data pt-2">
                <h4 class="text-uppercase grey-text">Aussteller</h4>
                <h4 class="font-weight-bold dark-grey-text">{{ supplierData.all }}</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-progress class="mb-2" :height="16" :value=talkPercentage color="green" />
              <mdb-card-text class="mb-4">{{ supplierData.talk }} von {{ supplierData.all }} halten einen Fachvortrag (<span class="font-weight-bold">{{ talkPercentage }}%</span>)</mdb-card-text>
              <mdb-progress class="mb-2" :height="16" :value=appPercentage color="green" />
              <mdb-card-text class="mb-4">{{ supplierData.app }} von {{ supplierData.all }} nutzen digitale Anfrageerfassung (<span class="font-weight-bold">{{ appPercentage }}%</span>)</mdb-card-text>
              <hr class="mt-4 mb-4">
              <mdb-icon icon="glass-cheers" size="lg" color="grey" /> <b>{{ supplierData.dinner }}</b> <span class="grey-text">Personen nehmen am Abendessen teil</span>
              <section v-show="suppliersDetails === true">
                <hr class="mt-4 mb-4">
                <p class="grey-text"><mdb-icon icon="clipboard-check" size="lg" color="grey" /> Checkliste Aussteller</p>
                <mdb-progress class="mb-2" :height="16" :value=appAuthorizedPercentage color="red" v-if="appAuthorizedPercentage < 50" />
                <mdb-progress class="mb-2" :height="16" :value=appAuthorizedPercentage color="orange" v-if="appAuthorizedPercentage >= 50 && appAuthorizedPercentage < 75" />
                <mdb-progress class="mb-2" :height="16" :value=appAuthorizedPercentage color="yellow" v-if="appAuthorizedPercentage >= 75 && appAuthorizedPercentage < 100" />
                <mdb-progress class="mb-2" :height="16" :value=appAuthorizedPercentage color="green" v-if="appAuthorizedPercentage === 100" />
                <mdb-card-text class="mb-4">{{ supplierData.authorized }} von {{ supplierData.app }} Accounts versendet (<span class="font-weight-bold">{{ appAuthorizedPercentage }}%</span>)</mdb-card-text>
                <mdb-progress class="mb-2" :height="16" :value=statusPercentage color="red" v-if="statusPercentage < 50" />
                <mdb-progress class="mb-2" :height="16" :value=statusPercentage color="orange" v-if="statusPercentage >= 50 && statusPercentage < 75" />
                <mdb-progress class="mb-2" :height="16" :value=statusPercentage color="yellow" v-if="statusPercentage >= 75 && statusPercentage < 100" />
                <mdb-progress class="mb-2" :height="16" :value=statusPercentage color="green" v-if="statusPercentage === 100" />
                <mdb-card-text class="mb-0">{{ supplierData.status }} von {{ supplierData.all }} als abgeschlossen markiert (<span class="font-weight-bold">{{ statusPercentage }}%</span>)</mdb-card-text>
              </section>
            </mdb-card-body>
            <mdb-card-footer class="text-muted mt-2">
              <mdb-switch
                offLabel="mehr anzeigen"
                onLabel=""
                class="float-right"
                v-model="suppliersDetails"
              />
            </mdb-card-footer>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Suppliers-->
        <!--Card: Participants-->
        <mdb-col xl="4" lg="6" md="12" sm="12" class="mb-4 pb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="users" class="grey"></mdb-icon>
              <div class="data pt-2">
                <h4 class="text-uppercase grey-text">Teilnehmer</h4>
                <h4 class="font-weight-bold dark-grey-text">{{ visitationData.visited }}</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-progress class="mb-2" :height="16" :value=errorPercentage color="red" v-show="participantsDetails === true" />
              <mdb-card-text class="mb-4" v-show="participantsDetails === true">{{ this.invitationData.error }} von {{ this.invitationData.all }} Einladungen fehlgeschlagen (<span class="font-weight-bold">{{ errorPercentage }}%</span>)</mdb-card-text>
              <mdb-progress class="mb-2" :height="16" :value=confirmedPercentage color="green" />
              <mdb-card-text class="mb-4">{{ responseData.confirmed }} von {{ invitedCount }} haben zugesagt (<span class="font-weight-bold">{{ confirmedPercentage }}%</span>)</mdb-card-text>
              <mdb-progress class="mb-2" :height="16" :value=cancelledPercentage color="red" v-show="participantsDetails === true" />
              <mdb-card-text class="mb-4" v-show="participantsDetails === true">{{ responseData.cancelled }} von {{ invitedCount }} Teilnehmern haben abgesagt (<span class="font-weight-bold">{{ cancelledPercentage }}%</span>)</mdb-card-text>
              <mdb-progress class="mb-2" :height="16" :value=deletedPercentage color="red" v-show="participantsDetails === true" />
              <mdb-card-text class="mb-4" v-show="participantsDetails === true">{{ this.responseData.deleted }} von {{ invitedCount }} Teilnehmern haben eine Löschung nach DSGVO beantragt (<span class="font-weight-bold">{{ deletedPercentage }}%</span>)</mdb-card-text>
              <mdb-progress class="mb-2" :height="16" :value=pendingPercentage color="red" v-show="participantsDetails === true" />
              <mdb-card-text class="mb-4" v-show="participantsDetails === true">{{ this.responseData.pending }} von {{ invitedCount }} Teilnehmern haben sich noch nicht zurückgemeldet (<span class="font-weight-bold">{{ pendingPercentage }}%</span>)</mdb-card-text>
              <mdb-progress class="mb-2" :height="16" :value=visitedPercentage color="green" />
              <mdb-card-text class="mb-4">{{ visitationData.visited }} von {{ ticketData.authorized }} sind erschienen (<span class="font-weight-bold">{{ visitedPercentage }}%</span>)</mdb-card-text>
              <section v-show="participantsDetails === true">
                <hr class="mt-4 mb-4">
                <p class="grey-text"><mdb-icon icon="clipboard-check" size="lg" color="grey" /> Checkliste Teilnehmer</p>
                <mdb-progress class="mb-2" :height="16" :value=invitedPercentage color="red" v-if="invitedPercentage < 50" />
                <mdb-progress class="mb-2" :height="16" :value=invitedPercentage color="orange" v-if="invitedPercentage >= 50 && invitedPercentage < 75" />
                <mdb-progress class="mb-2" :height="16" :value=invitedPercentage color="yellow" v-if="invitedPercentage >= 75 && invitedPercentage < 100" />
                <mdb-progress class="mb-2" :height="16" :value=invitedPercentage color="green" v-if="invitedPercentage === 100" />
                <mdb-card-text class="mb-4">{{ invitationData.invited }} von {{ invitationData.all }} Einladungen versendet (<span class="font-weight-bold">{{ invitedPercentage }}%</span>)</mdb-card-text>
                <mdb-progress class="mb-2" :height="16" :value=authorizedPercentage color="red" v-if="authorizedPercentage < 50" />
                <mdb-progress class="mb-2" :height="16" :value=authorizedPercentage color="orange" v-if="authorizedPercentage >= 50 && authorizedPercentage < 75" />
                <mdb-progress class="mb-2" :height="16" :value=authorizedPercentage color="yellow" v-if="authorizedPercentage >= 75 && authorizedPercentage < 100" />
                <mdb-progress class="mb-2" :height="16" :value=authorizedPercentage color="green" v-if="authorizedPercentage === 100" />
                <mdb-card-text class="mb-0">{{ ticketData.authorized }} von {{ responseData.confirmed }} Tickets versendet (<span class="font-weight-bold">{{ authorizedPercentage }}%</span>)</mdb-card-text>
              </section>
            </mdb-card-body>
            <mdb-card-footer class="text-muted mt-2">
              <mdb-switch
                offLabel="mehr anzeigen"
                onLabel=""
                class="float-right"
                v-model="participantsDetails"
              />
            </mdb-card-footer>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Participants-->
        <!--Card: Requests-->
        <mdb-col xl="4" lg="6" md="12" sm="12" class="mb-4 pb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="handshake" class="grey"></mdb-icon>
              <div class="data pt-2">
                <h4 class="text-uppercase grey-text">Anfragen</h4>
                <h4 class="font-weight-bold dark-grey-text">{{ requestData.all }}</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-progress class="mb-2" :height="16" :value=clientsPercentage color="green" />
              <mdb-card-text class="mb-4">{{ requestData.clients }} von {{ visitationData.visited }} Teilnehmern haben angefragt (<span class="font-weight-bold">{{ clientsPercentage }}%</span>)</mdb-card-text>
              <mdb-progress class="mb-2" :height="16" :value=suppliersPercentage color="green" />
              <mdb-card-text class="mb-4">{{ requestData.suppliers }} von {{ supplierData.all }} Ausstellern haben angeboten (<span class="font-weight-bold">{{ suppliersPercentage }}%</span>)</mdb-card-text>
              <section v-show="requestsDetails === true">
                <hr class="mt-4 mb-4">
                <p class="grey-text"><mdb-icon icon="clipboard-check" size="lg" color="grey" /> Checkliste Anfragen</p>
                <mdb-progress class="mb-2" :height="16" :value=clearancePercentage color="red" v-if="clearancePercentage < 50" />
                <mdb-progress class="mb-2" :height="16" :value=clearancePercentage color="orange" v-if="clearancePercentage >= 50 && clearancePercentage < 75" />
                <mdb-progress class="mb-2" :height="16" :value=clearancePercentage color="yellow" v-if="clearancePercentage >= 75 && clearancePercentage < 100" />
                <mdb-progress class="mb-2" :height="16" :value=clearancePercentage color="green" v-if="clearancePercentage === 100" />
                <mdb-card-text class="mb-0">{{ requestData.clearance }} von {{ requestData.all }} als abgeschlossen markiert (<span class="font-weight-bold">{{ clearancePercentage }}%</span>)</mdb-card-text>
              </section>
            </mdb-card-body>
            <mdb-card-footer class="text-muted mt-2">
              <mdb-switch
                offLabel="mehr anzeigen"
                onLabel=""
                class="float-right"
                v-model="requestsDetails"
              />
            </mdb-card-footer>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Requests-->
      </mdb-row>
    </section>
    <!--/.Section: Statistics-->
  </mdb-container>
</template>

<script>
import { bus } from '../../main'
import { mdbContainer, mdbRow, mdbCard, mdbCardBody, mdbCardFooter, mdbCardText, mdbCol, mdbIcon, mdbView, mdbProgress, mdbTbl, mdbTblBody, mdbTblHead, mdbSwitch } from 'mdbvue'
import axios from 'axios'

export default {
  name: 'Dashboard',
  components: {
    mdbContainer,
    mdbRow,
    mdbCard,
    mdbCardBody,
    mdbCardFooter,
    mdbCardText,
    mdbCol,
    mdbIcon,
    mdbView,
    mdbProgress,
    mdbTbl,
    mdbTblBody,
    mdbTblHead,
    mdbSwitch
  },
  data() {
    return {
      eventData: [],
      credentialsData: [],
      invitationData: [],
      responseData: [],
      ticketData: [],
      visitationData: [],
      supplierData: [],
      requestData: [],
      usergroup: localStorage.getItem('usergroup'),
      eventDetails: false,
      checklistDetails: false,
      suppliersDetails: false,
      participantsDetails: false,
      requestsDetails: false
    }
  },
  computed: {
    invitedPercentage() {
      return this.calculatePercentage(this.invitationData.invited, this.invitationData.all)
    },
    errorPercentage() {
      return this.calculatePercentage(this.invitationData.error, this.invitationData.all)
    },
    invitedCount() {
      return this.invitationData.invited - this.invitationData.error
    },
    responsePercentage() {
      return this.calculatePercentage(this.responseData.all, this.invitedCount)
    },
    confirmedPercentage() {
      return this.calculatePercentage(this.responseData.confirmed, this.invitedCount)
    },
    cancelledPercentage() {
      return this.calculatePercentage(this.responseData.cancelled, this.invitedCount)
    },
    deletedPercentage() {
      return this.calculatePercentage(this.responseData.deleted, this.invitedCount)
    },
    pendingPercentage() {
      return 100 - this.confirmedPercentage - this.cancelledPercentage - this.deletedPercentage
    },
    authorizedPercentage() {
      return this.calculatePercentage(this.ticketData.authorized, this.responseData.confirmed)
    },
    visitedPercentage() {
      return this.calculatePercentage(this.visitationData.visited, this.ticketData.authorized)
    },
    clientsPercentage() {
      return this.calculatePercentage(this.requestData.clients, this.visitationData.visited)
    },
    suppliersPercentage() {
      return this.calculatePercentage(this.requestData.suppliers, this.supplierData.all)
    },
    clearancePercentage() {
      return this.calculatePercentage(this.requestData.clearance, this.requestData.all)
    },
    statusPercentage() {
      return this.calculatePercentage(this.supplierData.status, this.supplierData.all)
    },
    talkPercentage() {
      return this.calculatePercentage(this.supplierData.talk, this.supplierData.all)
    },
    appPercentage() {
      return this.calculatePercentage(this.supplierData.app, this.supplierData.all)
    },
    appAuthorizedPercentage() {
      return this.calculatePercentage(this.supplierData.authorized, this.supplierData.app)
    },
    checklistPercentage() {
      var sumCompleted = 0
      var sumAll = 0
      if (this.checklistDetails === true) {
        sumCompleted = this.supplierData.authorized + this.invitationData.invited + this.ticketData.authorized + this.supplierData.status + this.requestData.clearance
        sumAll = this.supplierData.app + this.invitationData.all + this.responseData.confirmed + this.supplierData.all + this.requestData.all
      } else {
        sumCompleted = this.supplierData.authorized + this.invitationData.invited + this.ticketData.authorized
        sumAll = this.supplierData.app + this.invitationData.all + this.responseData.confirmed
      }
      return this.calculatePercentage(sumCompleted, sumAll)
    }
  },
  created() {
    bus.$on('eventChange', this.loadModel)
  },
  beforeDestroy() {
    bus.$off('eventChange', this.loadModel)
  },
  mounted: function() {
    if (localStorage.usergroup) {
      this.usergroup = localStorage.usergroup
    }

    this.loadModel()
  },
  methods: {
    loadModel () {
      axios.get('get_event.php').then(response => {
        this.eventData = response.data
      })
      axios.get('get_credentials.php').then(response => {
        this.credentialsData = response.data
      })
      axios.get('chart_invitations.php').then(response => {
        this.invitationData = response.data
      })
      axios.get('chart_responses.php').then(response => {
        this.responseData = response.data
      })
      axios.get('chart_tickets.php').then(response => {
        this.ticketData = response.data
      })
      axios.get('chart_visitations.php').then(response => {
        this.visitationData = response.data
      })
      axios.get('chart_suppliers.php').then(response => {
        this.supplierData = response.data
      })
      axios.get('chart_requests.php').then(response => {
        this.requestData = response.data
      })
    },
    calculatePercentage: function (fraction, whole) {
      var percentage = fraction / whole * 100
      if (percentage > 99) {
        percentage = Math.floor(percentage)
      } else {
        percentage = Math.round(percentage)
      }
      if (!isNaN(percentage)) {
        return percentage  
      } else {
        return 0
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .switch label input[type="checkbox"]:checked+.lever:after {
    background-color: #9e9e9e !important;
  }

  .dashboard .card {
    height: 100% !important;
  }
</style>
