<template>
  <mdb-container fluid>
    <!-- Section: Grid -->
    <section style="height: calc(100vh - 117px);">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :toolbar="toolbar"
        :toolbarClick="clickToolbar"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :selectionSettings="selectionOptions"
        :rowSelected="onRowSelected"
        :rowDeselected="onRowDeselected"
        :recordDoubleClick="recordDoubleClick"
        :editSettings="editSettings"
        :queryCellInfo="queryCellInfo"
      >
        <e-columns>
          <e-column
            type='checkbox'
            width='50'
          ></e-column>
          <e-column
            field="id"
            headerText="ID"
            textAlign="Left"
            :isPrimaryKey="true"
            :visible="false"
          ></e-column>
          <e-column
            field="created"
            headerText="Datum"
            textAlign="Left"
          ></e-column>
          <e-column
            field="supplier_associate"
            headerText="Erfasst von"
            textAlign="Left"
          ></e-column>
          <e-column
            field="supplier_company"
            headerText="Aussteller"
            textAlign="Left"
          ></e-column>
          <e-column
            field="client_company"
            headerText="Firma"
            textAlign="Left"
          ></e-column>
          <e-column
            field="client_title"
            headerText="Titel"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="client_last_name"
            headerText="Titel"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="client_full_name"
            headerText="Ansprechpartner"
            textAlign="Left"
          ></e-column>
          <e-column
            field="product_item_number"
            headerText="Artikelnummer"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="product_item_variant"
            headerText="Variante"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="product_label"
            headerText="Bezeichnung"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="product_full_label"
            headerText="Anfrage"
            textAlign="Left"
          ></e-column>
          <e-column
            field="supplier_note"
            headerText="Notiz"
            textAlign="Left"
          ></e-column>
          <e-column
            field="clearance"
            headerText="<i class='fas fa-check' data-toggle='tooltip' data-placement='top' title='Status'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="72"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </section>
    <!-- /Section: Grid -->
    <!-- Modal: catalog -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showModal"
      @close="closeModal"
    >
      <mdb-modal-header>
        <mdb-modal-title v-if="modalEdit">Anfrage bearbeiten</mdb-modal-title>
        <mdb-modal-title v-else>Katalog hinzufügen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="modal-form" novalidate @submit.prevent="saveModal" @keydown.enter.prevent>
          <mdb-row>
            <mdb-col>
              <mdb-row>
                <mdb-col>
                  <p v-show="model.finishing !== ' ()<br>'" v-html="model.finishing"></p>
                  <legend><mdb-icon icon="comment" class="red-text mt-4" /> Notiz</legend>
                  <vue-editor
                    id="memoEditor"
                    v-model="model.supplier_note"
                    :editor-toolbar="editorToolbar"
                  />
                </mdb-col>
              </mdb-row>
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="red" @click.native="closeModal">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" v-if="modalEdit">Speichern</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" v-else>Hinzufügen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: catalog -->
    <!-- Modal: Delete -->
    <mdb-modal :show="showDelete" @close="showDelete = false" size="lg" danger>
      <mdb-modal-header>
        <p class="heading"><strong>Anfragen löschen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="ban" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die gewählten Anfragen wirklich löschen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.client_company">
                      {{ record.client_company }} - {{ record.product_full_label }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" @click="showDelete = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="danger" @click.native="deleteEntry">Löschen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Delete -->
    <!-- Modal: registerRecords -->
    <mdb-modal :show="showRegisterRecords" @close="showRegisterRecords = false">
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Bearbeitungsstatus ändern</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="check-square" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie den Bearbeitungsstatus der gewählten Anfragen ändern?</strong>
            </p>
            <mdb-card>
              <mdb-card-body class="pb-0">
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <mdb-select
                    label="Status"
                    class="mt-3"
                    v-model="action.register"
                    ref="bulk_register"
                    search
                  />
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.client_company">
                      {{ record.client_company }} - {{ record.product_full_label }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showRegisterRecords = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" @click.native="registerRecords">Ändern</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: registerRecords -->
    <!-- Modal: exportRecords -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showExportRecords"
      @shown="exportRecordsShown"
      @close="showExportRecords"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Anfragen exportieren</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="file-export" fas size="4x" class="red-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Wählen Sie aus, welche Anfragen exportiert werden soll:
            </p>
            <mdb-input type="radio" id="export-option-1" name="exportOptions" radioValue="selection" v-model="action.export" :disabled="this.selectedRecords.length === 0" class="mb-2" label="Alle Anfragen passend zu Kunden aus der Auswahl" />
            <mdb-input type="radio" id="export-option-5" name="exportOptions" radioValue="restricted" v-model="action.export" class="mb-2" label="Alle Anfragen eines Ausstellers" v-show="this.usergroup === 'trader'" />
            <mdb-input type="radio" id="export-option-4" name="exportOptions" radioValue="all" v-model="action.export" class="mb-2" label="Alle Anfragen" />
            <mdb-input type="radio" id="export-option-2" name="exportOptions" radioValue="pending" v-model="action.export" class="mb-2" label="Alle Anfragen mit Status 'offen'" />
            <mdb-input type="radio" id="export-option-3" name="exportOptions" radioValue="clearance" v-model="action.export" class="mb-2" label="Alle Anfragen mit Status 'bearbeitet'" />
            <hr>
            <mdb-input type="radio" id="export-option-13" name="exportOptions" radioValue="export_table" v-model="action.export" class="mb-2" label="Alle Datensätze als Tabelle (XLSX)" />
            
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <div class="mb-2 pl-4">Ausgewählte Anfragen:</div>
                  <ul class="mb-0" v-if="this.action.export === 'selection'">
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.client_company">
                      {{ record.client_company }} - {{ record.product_full_label }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                  <p v-if="this.action.export === 'pending'" class="pl-4">{{ exportRecordCount }} Anfragen</p>
                  <p v-if="this.action.export === 'clearance'" class="pl-4">{{ exportRecordCount }} Anfragen</p>
                  <p v-if="this.action.export === 'all' || this.action.export === 'export_table'" class="pl-4">{{ exportRecordCount }} Anfragen</p>
                  <p v-if="this.action.export === 'restricted'" class="pl-4">
                    <mdb-select
                      label="Aussteller"
                      class="mt-3"
                      v-model="action.supplier"
                      ref="supplier_export"
                      @getValue="getSupplierValue"
                      search
                    />
                    {{ exportRecordCount }} Anfragen
                  </p>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showExportRecords = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" @click.native="exportRecords">Exportieren</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: exportRecords -->
  </mdb-container>
</template>

<script>
function clearSelection() {
  if (window.getSelection) {
    window.getSelection().removeAllRanges()
  } else if (document.selection) {
    document.selection.empty()
  }
}

import Vue from 'vue'
import { bus } from '../../main'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as cagregorian from 'cldr-data/main/de/ca-gregorian.json'
import * as numbers from 'cldr-data/main/de/numbers.json'
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencies from 'cldr-data/main/de/currencies.json'
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base'
import { GridPlugin, Resize, Toolbar, Sort, Search, Edit } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText,
  mdbInput,
  mdbSelect
} from 'mdbvue'
import { VueEditor } from "vue2-editor"

L10n.load({
  'de': {
    'grid': {
      'EmptyRecord': 'Keine Einträge gefunden',
      'GroupDropArea': 'Spalte hier ablegen um zu gruppieren',
      'UnGroup': 'Klicken Sie hier, um die Gruppierung aufheben',
      'Item': 'Eintrag',
      'Items': 'Einträge',
      'Search': 'Suche',
      'FilterButton': 'Filtern',
      'ClearButton': 'Löschen',
      'SelectAll': 'Alle',
      'Blanks': 'Leer',
      'Add': 'Hinzufügen',
      'Edit': 'Bearbeiten',
      'Delete': 'Löschen',
      'Enter the value': 'Suchbegriff eingeben'
    },
    'pager': {
      'currentPageInfo': '{0} von {1} Seiten',
      'totalItemsInfo': '({0} Einträge)',
      'firstPageTooltip': 'Zur ersten Seite',
      'lastPageTooltip': 'Zur letzten Seite',
      'nextPageTooltip': 'Zur nächsten Seite',
      'previousPageTooltip': 'Zurück zur letzten Seit',
      'nextPagerTooltip': 'Zum nächsten Pager',
      'previousPagerTooltip': 'Zum vorherigen Pager'
    }
  }
})
loadCldr(numberingSystems, cagregorian, numbers, timeZoneNames, weekData, currencies)
setCulture('de')
setCurrencyCode('EUR')

Vue.use(GridPlugin)

export default {
  name: 'requests',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbInput,
    mdbSelect,
    VueEditor
  },
  data () {
    return {
      model: {
        id: 'new',
        supplier_note: '',
        finishing: ''
      },
      action: {
        selection: [],
        restrict: 0,
        supplier: [],
        register: [
          { text: 'Bearbeitung abgeschlossen', value: 'clear', selected: true },
          { text: 'Offen', value: 'pending', selected: false }
        ],
        export: 'all'
      },
      selectedRecords: [],
      exportRecordCount: 0,
      showModal: false,
      modalEdit: false,
      showDelete: false,
      showRegisterRecords: false,
      showExportRecords: false,
      wasValidated: false,
      usergroup: localStorage.getItem('usergroup'),
      dataSource: new DataManager({
        url: Vue.prototype.$serverUrl + 'api/list_requests.php',
        adaptor: new UrlAdaptor,
        crossDomain: true
      }),
      toolbar: [
        { text: 'Notiz bearbeiten', tooltipText: 'Notiz bearbeiten', prefixIcon: 'e-edit', id: 'editRecord' },
        { text: 'Bearbeitungsstatus', tooltipText: 'Status der Anfragen ändern', prefixIcon: 'e-icon-check-box', id: 'registerRecords' },
        { text: 'Löschen', tooltipText: 'Anfragen löschen', prefixIcon: 'e-delete-4', id: 'deleteRecord' },
        { type: 'Separator' },
        { text: 'Export', tooltipText: 'Anfragen exportieren', prefixIcon: 'e-icon-export', id: 'exportRecords' },
        'Search'
      ],
      editorToolbar: [
        ["bold", "italic", "underline"]
      ],
      sortSettings: {
        columns: [{
          field: 'created',
          direction: 'Descending'
        }]
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        allowEditOnDblClick: false
      },
      selectionOptions: {
        checkboxMode: 'Default'
      },
      labelColumnAttributes : {
        class: 'label-column'
      }
    }
  },
  created() {
    bus.$on('eventChange', this.eventChange)
  },
  beforeDestroy() {
    bus.$off('eventChange', this.eventChange)
  },
  mounted: function() {
    if (localStorage.usergroup) {
      this.usergroup = localStorage.usergroup
    }
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('keyup', () => {
      this.$refs.grid.search((event.target).value)
    });
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('click', () => {
      this.$refs.grid.ej2Instances.searchSettings.key = '';
    });
    this.resetModel()
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['registerRecords'], false)
  },
  computed: {
    exportAction() {
      return this.action.export;
    }
  },
  watch: {
    selectedRecords: {
      handler: function () {
        if (this.selectedRecords.length === 0) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['registerRecords'], false)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['registerRecords'], true)
        }
        if (this.selectedRecords.length === 1) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], true)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
        }
      },
      deep: true
    },
    exportAction: {
      handler: function () {
        axios.get('get_request_count.php?action=' + this.action.export + '&restrict=' + this.action.restrict).then(response => {
          this.exportRecordCount = response.data
        })
      }
    }
  },
  methods: {
    eventChange: function () {
      this.$refs.grid.refresh()
    },
    dataBound: function () {
      this.$refs.grid.autoFitColumns(['created', 'supplier_associate', 'supplier_company', 'client_company', 'client_full_name', 'product_full_label', 'client_sample'])
    },
    queryCellInfo: function (args) {
      if (args.column.field === 'clearance') {
        if (args.data['clearance'] === 1) {
          args.cell.classList.add('status-circle', 'status-grey')
        } else if (args.data['clearance'] === 2) {
          args.cell.classList.add('status-circle', 'status-green')
        }
        args.cell.textContent = ''
      }
    },
    clickToolbar: function (args) {
      if (args.item.id === 'editRecord') {
        axios.get('get_request.php?id=' + this.selectedRecords[0].id).then(response => {
          this.model = response.data
          this.showModal = true
          this.modalEdit = true
        })
      }
      if (args.item.id === 'deleteRecord') {
        this.action.selection = []
        for (var i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showDelete = true
      }
      if (args.item.id === 'registerRecords') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showRegisterRecords = true
      }
      if (args.item.id === 'exportRecords') {
        this.action.selection = []
        for (var j = 0; j < this.selectedRecords.length; j++) {
          this.action.selection.push(this.selectedRecords[j].id)
        }
        this.showExportRecords = true
      }
    },
    onRowSelected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    onRowDeselected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    recordDoubleClick: function(row) {
      clearSelection()
      this.$refs.grid.clearRowSelection()
      this.$refs.grid.selectRow(row.rowIndex)
      axios.get('get_request.php?id=' + row.rowData.id).then(response => {
        this.model = response.data
        this.showModal = true
        this.modalEdit = true
      })
    },
    closeModal () {
      this.showModal = false
      this.resetModel()
    },
    saveModal(event) {
      this.wasValidated = true
      event.target.classList.add('was-validated')
      var url = ''
      if (this.modalEdit === true) {
        url = 'edit_request.php'
      } else {
        url = 'add_request.php'
      }
      axios({
        method: 'post',
        url: url,
        data: this.model
      }).then(response => {
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          if (this.modalEdit === true) {
            var rowIndex = this.$refs.grid.getRowIndexByPrimaryKey(this.model.id)
            this.$refs.grid.updateRow(rowIndex, this.model)
          } else {
            this.$refs.grid.refresh()
          }
          this.showModal = false
          this.resetModel()
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    deleteEntry () {
      var url = 'delete_request.php'
      axios({
        method: 'post',
        url: url,
        data: this.action.selection
      }).then(response => {
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showDelete = false
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    registerRecords () {
      var url = 'register_request.php'
      const payload = {
        ids: this.action.selection,
        action: this.action.register
      }
      axios({
        method: 'post',
        url: url,
        data: payload
      }).then(response => {
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showRegisterRecords = false
          this.resetModel()
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    getSupplierValue (value) {
      this.action.restrict = value
      axios.get('get_request_count.php?action=' + this.action.export + '&restrict=' + this.action.restrict).then(response => {
        this.exportRecordCount = response.data
      })
    },
    exportRecordsShown () {
      axios.get('get_request_count.php?action=' + this.action.export + '&restrict=' + this.action.restrict).then(response => {
        this.exportRecordCount = response.data
      })
    },
    exportRecords () {
      var url = Vue.prototype.$serverUrl + 'api/export_requests.php?action=' + this.action.export + '&restrict=' + this.action.restrict + '&ids=' + this.action.selection.join(',')
      window.open(url)
    },
    resetModel () {
      this.wasValidated = false
      this.model.id = 'new'
      this.model.supplier_note = ''
      this.model.finishing = ''
      axios.get('select_suppliers.php').then(response => {
        this.action.supplier = response.data
        this.action.restrict = this.action.supplier[0].value
      })
    }
  },
  provide: {
    grid: [Toolbar, Search, Resize, Sort, Edit]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.e-grid .e-rowcell.label-column {
  font-weight: normal;
  font-size: 16px;
}

.padding-label label {
  margin-bottom: 0px;
  margin-top: 14px;
}

.select-wrapper {
  position: relative;
  top: -8px;
}

.custom2 .select-wrapper {
  top: -24px;
}

.custom3 .select-wrapper {
  top: -24px;
}

.row .col-md-3 {
  height: 75px !important;
}

.modal-without-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: initial !important;
}

</style>
