import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from '@/components/dashboard/Dashboard'
import Participants from '@/components/participants/Participants'
import Acquisitions from '@/components/acquisitions/Acquisitions'
import Accounts from '@/components/accounts/Accounts'
import Catalogs from '@/components/catalogs/Catalogs'
import Requests from '@/components/requests/Requests'
import Groups from '@/components/groups/Groups'
import SettingsExhibitor from '@/components/settings/SettingsExhibitor'
import SettingsParticipant from '@/components/settings/SettingsParticipant'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/teilnehmer',
      name: 'Teilnehmer',
      component: Participants
    },
    {
      path: '/akquise',
      name: 'Aussteller Akquise',
      component: Acquisitions
    },
    {
      path: '/aussteller',
      name: 'Aussteller Anmeldungen',
      component: Accounts
    },
    {
      path: '/kataloge',
      name: 'Kataloge',
      component: Catalogs
    },
    {
      path: '/anfragen',
      name: 'Anfragen',
      component: Requests
    },
    {
      path: '/gruppen',
      name: 'Gruppen',
      component: Groups
    },
    {
      path: '/einstellungen/aussteller',
      name: 'Einstellungen Aussteller',
      component: SettingsExhibitor
    },
    {
      path: '/einstellungen/teilnehmer',
      name: 'Einstellungen Teilnehmer',
      component: SettingsParticipant
    }
  ]
})
